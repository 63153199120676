.dynamic-down[data-v-3ba7ed96] {
  position: relative;
  width: 100%;
  height: 24px;
  line-height: 24px;
  border: 1px solid #c2c2c2;
  border-radius: 3px;
  box-sizing: border-box;
}
.dynamic-down .text[data-v-3ba7ed96] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  font-size: 13px;
  color: #606266;
  cursor: pointer;
}
.dynamic-down .row-ellipsis[data-v-3ba7ed96] {
  padding-right: 10px;
}
.dynamic-down .select-box[data-v-3ba7ed96] {
  position: absolute;
  left: 0;
  min-width: 100%;
  background: #fff;
  z-index: 10000;
}
.dynamic-down .select-box.top[data-v-3ba7ed96] {
  bottom: calc(100% + 10px);
}
.dynamic-down .select-box.bottom[data-v-3ba7ed96] {
  top: calc(100% + 10px);
}
.dynamic-down .select-icon[data-v-3ba7ed96] {
  transition: all 0.3s;
}
.dynamic-down .select-icon.arrow-up[data-v-3ba7ed96] {
  transform: rotate(180deg);
}