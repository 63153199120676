.boxShadowNone[data-v-185e7c80] .el-input__wrapper {
  box-shadow: none;
  background: none;
}
.top_title[data-v-185e7c80] {
  width: 100%;
  margin: 0 auto;
}
.top_title h5[data-v-185e7c80] {
  font-size: 30px;
  color: #333;
  text-align: center;
  font-weight: 500;
  margin-bottom: 36px;
}
.top_input[data-v-185e7c80] {
  width: 30%;
}
.top_input h6[data-v-185e7c80] {
  float: left;
  width: 60px;
  font-size: 12px;
  color: #333;
  font-weight: normal;
  line-height: 36px;
  margin-right: 10px;
}
.top_input2[data-v-185e7c80] {
  margin: 0 auto;
  position: relative;
}
.top_input2 h6[data-v-185e7c80] {
  float: left;
  width: 60px;
  font-size: 12px;
  color: #333;
  font-weight: normal;
  line-height: 28px;
  margin-right: 10px;
}
.top_input2 input[data-v-185e7c80] {
  float: left;
  width: 47%;
  border: none;
  border-bottom: 1px solid #666;
  margin-left: 5px;
  line-height: 22px;
}
.content[data-v-185e7c80] {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #008000;
  margin-top: 10px;
  border-right: none;
}
.grid-content[data-v-185e7c80] {
  position: relative;
  min-height: 38px;
  font-size: 12px;
  color: #666;
  line-height: 38px;
  text-align: center;
}
.row_box2[data-v-185e7c80] {
  width: 100%;
  height: 154px;
}
.height_font[data-v-185e7c80] {
  width: 100%;
  height: 152px;
  margin: 0 auto;
  text-align: center;
}
.height_font p[data-v-185e7c80] {
  width: 20px;
  margin: 0 auto;
  height: 100%;
  line-height: 28px;
  padding-top: 22px;
}
.list_info[data-v-185e7c80] {
  line-height: 24px;
}
.list_info.red li[data-v-185e7c80] {
  border-bottom: 1px solid #d56400 !important;
}
.list_info li[data-v-185e7c80] {
  position: relative;
  line-height: 38px;
  height: 38px;
  font-size: 12px;
  color: #333;
  border-bottom: 1px solid #008000;
}
.list_info li[data-v-185e7c80]:last-child {
  border-bottom: none;
}
.list_info li input[data-v-185e7c80] {
  width: 100%;
  margin: 0 auto;
  height: 36px;
  line-height: 36px;
  font-size: 12px;
  color: #333;
  background: none;
  border: none;
  padding: 0 5px;
}
.weight[data-v-185e7c80] {
  font-weight: 700;
}
.weight li[data-v-185e7c80] {
  font-weight: 600 !important;
}
.star[data-v-185e7c80] {
  color: #f15a24;
  height: 12px;
  font-size: 18px;
  display: inline-block;
  z-index: 999;
}
.input_box[data-v-185e7c80] {
  width: 100%;
  height: 152px;
  font-size: 12px;
  color: #666;
  text-align: center;
}
.top_font[data-v-185e7c80] {
  border-bottom: 1px solid #008000;
  line-height: 38px;
  height: 39px;
  font-size: 12px;
  color: #333;
}
.item_box[data-v-185e7c80] {
  width: 100%;
  margin: 0 auto;
  border-top: 1px solid #008000;
  position: relative;
}
.item_box .close[data-v-185e7c80] {
  width: 1.4em;
  height: 1.4em;
  vertical-align: -0.15em;
  fill: currentColor;
  position: absolute;
  right: -32px;
  top: 9px;
  font-size: 20px;
  color: #ff1818;
  cursor: pointer;
}
.item_box .close .svg-icon[data-v-185e7c80] {
  width: 100%;
  height: 100%;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.item_box .add[data-v-185e7c80] {
  width: 1.4em;
  height: 1.4em;
  vertical-align: -0.15em;
  fill: currentColor;
  position: absolute;
  left: -28px;
  top: 9px;
  font-size: 16px;
  color: #f15a24;
  cursor: pointer;
}
.item_box .add .svg-icon[data-v-185e7c80] {
  width: 100%;
  height: 100%;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.item_box input[data-v-185e7c80] {
  width: 100%;
  margin: 0 auto;
  height: 38px;
  line-height: 38px;
  font-size: 12px;
  color: #333;
  background: none;
  border: none;
  padding: 0 5px;
}
.item_list[data-v-185e7c80] {
  width: 100%;
  margin: 0 auto;
  border-top: 1px solid #008000;
  position: relative;
}
.item_list .add[data-v-185e7c80] {
  position: absolute;
  right: -38px;
  top: 10px;
  width: 1.8em;
  height: 1.8em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
  color: #f15a24;
  cursor: pointer;
}
.item_list .add .svg-icon[data-v-185e7c80] {
  width: 100%;
  height: 100%;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.item_list .bg-purple[data-v-185e7c80] {
  line-height: 38px;
  height: 38px;
  font-size: 12px;
  color: #333;
  border-bottom: 1px solid #008000;
}
.item_list .bg-purple[data-v-185e7c80]:last-child {
  border-bottom: none;
}
.bg-purple[data-v-185e7c80] {
  text-align: center;
}
.bottom_num[data-v-185e7c80] {
  width: 20%;
  margin-top: 10px;
  font-size: 12px;
  color: #333;
  font-weight: normal;
  line-height: 36px;
}
.total[data-v-185e7c80] {
  width: 100%;
  margin: 0 auto;
  border-top: 1px solid #008000;
  line-height: 38px;
  height: 38px;
  font-size: 12px;
  color: #333;
}
.el-row[data-v-185e7c80] {
  width: 100%;
}
.el-col[data-v-185e7c80] {
  border-right: 1px solid #008000;
}
.special h5[data-v-185e7c80] {
  color: #d56400;
  border-bottom: 4px double #d56400;
}
.special h5 span[data-v-185e7c80] {
  border-bottom: 16px solid #d56400;
}
.special .el-col[data-v-185e7c80] {
  border-right: 1px solid #d56400;
}
.special .content[data-v-185e7c80] {
  border: 1px solid #d56400;
  border-right: none;
}
.special .list_info li[data-v-185e7c80] {
  border-bottom: 1px solid #d56400;
}
.special .list_info li[data-v-185e7c80]:last-child {
  border-bottom: none;
}
.special .textarea[data-v-185e7c80] {
  border-bottom: 1px solid #d56400;
}
.special .top_font[data-v-185e7c80] {
  border-bottom: 1px solid #d56400;
}
.special .item_list[data-v-185e7c80] {
  border-top: 1px solid #d56400;
}
.special .item_box[data-v-185e7c80] {
  border-top: 1px solid #d56400;
}
.special .total[data-v-185e7c80] {
  border-top: 1px solid #f15a24;
}
.red[data-v-185e7c80] {
  border-color: #d56400 !important;
}