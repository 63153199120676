.options-container[data-v-33ea6d58] {
  max-height: 294px;
  min-width: 100%;
  overflow-y: auto;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
}
.options-container .option-item[data-v-33ea6d58] {
  font-size: 13px;
  min-width: 100%;
  height: 28px;
  padding: 0 20px;
  line-height: 28px;
  color: #606266;
  text-align: left;
  white-space: nowrap;
  transition: all 0.3s;
  box-sizing: border-box;
  cursor: pointer;
}
.options-container .option-item[data-v-33ea6d58]:first-of-type {
  margin-top: 6px;
}
.options-container .option-item[data-v-33ea6d58]:last-of-type {
  margin-bottom: 6px;
}
.options-container .option-item[data-v-33ea6d58]:hover, .options-container .option-item.selected[data-v-33ea6d58] {
  background-color: #f5f7fa;
}
.options-container .option-item.selected[data-v-33ea6d58] {
  color: var(--themeColor, #17a2b8) !important;
  font-weight: 700;
}
.options-container .el-scrollbar[data-v-33ea6d58] {
  min-width: 100%;
}